import AOS from 'aos';
import 'jquery-validation';
import owlCarousel from 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css'

let userLogged = $('header .header__info .navbar-actions').attr('data-user-login');

// Verify if user is logged
function verifyUserLogged() {
  // if($('#sepro-menu .navbar-actions').length) {
  //   userLogged = true;
  //   $('#userLogged').remove();
  // }

  if (userLogged === 'True') {
    // Hide login button
    $('#btnLogin').addClass('d-none');
    $('#btnRegister').addClass('d-none');
    $('.user-name').removeClass('d-none');
    $('.benefits-section .benefits-section-info .benefits-info .btn').addClass('d-none');
    $('header .header__info .navbar-brand').addClass('logged')
    $('header .header__info .navbar-actions').addClass('logged')
    // Show logout menu
    const templateLink = `<li class="nav-item"><a class="nav-link" href="${window.location.origin}/cerrar-sesion" data-link="cerrar-sesion">X Cerrar sesión</a></li>`;
    const btnCloseSession = `<a class="btn btn-green-outline" href="${window.location.origin}/cerrar-sesion" data-link="cerrar-sesion">Salir</a>`;
    $('#menu .navbar-nav').append(templateLink);
    $('header .header__info .navbar-actions .bth__header').append(btnCloseSession)
    $('.header .row .navbar-menu #sepro-menu').addClass('logged');
    


    // Show button whatsapp
    // $('#btnWhatsapp').removeClass('d-none');

    // $('.header .btn-menutoggle').addClass('userlogin');
  }

  // Validate login for internal pages
  // $('.btn-navigation-pages').on('click', function (e) {
  //   e.preventDefault();

  //   if (userLogged) {
  //     window.location.href = $(this).attr('href');
  //   } else {
  //     $('#popregistro').addClass('show');
  //     $('html').addClass('overhidden');
  //   }
  // });

}

function addMethodValidate() {
  if ($('.box-form form').length) {
    // Validate email
    $.validator.addMethod("emailValidate", function (value, element) {
      if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)) {
        return true;
      } else {
        return false;
      };
    });

    // Validate letters only and spaces
    $.validator.addMethod("lettersonlys", function (value, element) {
      if (/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$/.test(value)) {
        return true;
      } else {
        return false;
      };
    });

    // Validate numbers only
    $.validator.addMethod("numericonly", function (value, element) {
      //console.log(value);
      if (/^[0-9]+$/i.test(value)) {
        return true;
      } else {
        return false;
      };
    });

    // Validate alphanumeric
    $.validator.addMethod("alphaNum", function (value, element) {

      if (/[a-zA-Z0-9 -()+]+$/i.test(value)) {
        return true;
      } else {
        return false;
      };
    });
  }
}

function validatePathURL() {
  const nameClass = $('main').attr('class');


  if (nameClass == 'about') {
    $('body').addClass('about');
  }
  if (nameClass == 'mastery' || nameClass == 'specialization') {
    $('body').addClass('postgraduate');
  }
  if (nameClass == 'conferences') {
    $('body').addClass('conferences');
  }

  // Validate path of recovery password for send email
  if (window.location.search == '?pass=true') {
    const templateModal = `
      <div id="popSendEmail" class="modal modal-sendemail d-none">
          <div class="modal-info modal-sendemail-info">
              <div class="modal-modalbox modal-sendemail-modalbox">
                  <div class="modalbox__header">
                      <h3 class="modalbox__title">Correo enviado</h3>
                      <a href="/" class="close"><span class="icon-cross"></span></button></a>
                  </div>
                  <div class="modalbox__body">
                      <p class="text-paragraph">Por favor revisa la bandeja de entrada en tu correo electrónico.</p>

                      <div class="btn__container">
                          <a href="/" class="btn btn-third">Volver</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    `;
    $('body').append(templateModal);
    $('#popSendEmail').removeClass('d-none').addClass('show');
    $('html').addClass('overhidden');
  }

  $('body').removeClass('d-none');
}

function showModalLogin() {

  $('#popregistro').removeClass('d-none');

  const boxForm = $('#section-home .box-form');
  const boxFormRegister = $('#section-home .box-form-register');
  const boxFormLogin = $('#section-home .box-form-login');
  const btnScroll = $('#boxScroll');

  const openModal = () => {
    $('#popregistro').toggleClass('show');
    $('html').toggleClass('overhidden');
  };

  const closeModal = () => {
    $('#popregistro').removeClass('show');
    $('html').removeClass('overhidden');
  };


  $('#popregistro .close').on('click', function () {
    closeModal();
  });

  // $('#btnRegister').on('click', function (e) {
  //   e.preventDefault();
  //   boxForm.removeClass('d-none');
  //   boxFormLogin.addClass('d-none');
  //   boxFormRegister.removeClass('d-none');
  //   btnScroll.addClass('d-none');
  //   closeModal();

  //   $('html, body').animate({
  //     scrollTop: boxFormRegister.offset().top - 100
  //   }, 700);
  // });

  // $('#btnLoginModal').on('click', function (e) {
  //   e.preventDefault();
  //   boxForm.removeClass('d-none');
  //   boxFormRegister.addClass('d-none');
  //   boxFormLogin.removeClass('d-none');
  //   btnScroll.addClass('d-none');
  //   closeModal();

  //   $('html, body').animate({
  //     scrollTop: boxFormLogin.offset().top - 100
  //   }, 700);
  // });


  if (location.pathname === '/iniciar-sesion') {
    openModal();
    $('#btnLogin').on('click', function (e) {
      e.preventDefault();
      openModal();
    });

  }

  if (location.pathname === '/crear-usuario') {
    openModal();
    $('#btnRegister').on('click', function (e) {
      e.preventDefault();
      openModal();
    });
  }
}

function showMenuNavigation() {
  if ($('.btn-menutoggle').length) {
    const btnMenu = $('.btn-menutoggle');
    const menu = $('#menu');
    menu.removeClass('d-none');

    btnMenu.on('click', function () {
      $('body').css('overflow', 'hidden')
      menu.addClass('show');
    });

    menu.find('.close').on('click', function () {
      $('body').css('overflow', 'auto')
      menu.removeClass('show');
    });
  }
}

function listeningScroll() {
  if ($('.uniminuto-brand').length) {
    $(window).on('scroll', function () {
      const scroll = $(this).scrollTop();

      if (scroll > 10) {
        $('.uniminuto-brand').fadeOut(300).addClass('fadeOut');
      } else {
        $('.uniminuto-brand').fadeIn(300).removeClass('fadeOut');
      }
    });
  }

  // if($('.header .colum-right .bth__header').length) {
  //   if($(window).width() < 992) {
  //     $(window).on('scroll', function () {
  //       const scroll = $(this).scrollTop();

  //       if (scroll > 65) {
  //         $('.header .colum-right .bth__header').addClass('fixed');
  //       } else {
  //         $('.header .colum-right .bth__header').removeClass('fixed');
  //       }
  //     });
  //   }
  // }
}

// Load data postgraduate to modal
function loadDataPostgraduateModal() {
  if ($('.modal-programans').length) {
    const urlDomain = window.location.origin;

    if (userLogged) {
      // Load Masteries
      $('#modalProgramsMaster .list__programs').load(`${urlDomain}/programa/listado/master/`);

      // Load Specializations
      $('#modalProgramsSpecialization .list__programs').load(`${urlDomain}/programa/listado/specialization/`);

    }
  }
}

// Navigation for menu internal pages
function listenClickMenu() {
  if ($('#menu').length) {
    $('#menu .nav-link').on('click', function (e) {
      e.preventDefault();
      let pagesClick = $(this).attr('data-link');
      window.scrollTo(0, 0);

      if (pagesClick == 'cerrar-sesion') {
        window.location.href = `${window.location.origin}/${pagesClick}`;
        return true;
      }

      if (window.location.pathname != '/home' && window.location.pathname != '/') {
        window.location.href = `${window.location.origin}/#${pagesClick}`;
        return true;
      }

      setTimeout(function () {
        $("#menu").removeClass('show');
        $("html, body").animate({
          scrollTop: $("#" + pagesClick + "").offset().top,
        }, 500);
      }, 1000);

    });
  }
}

// Show modal programs
function showModalPrograms() {
  if ($('.modal-programans').length) {
    const modalMaster = $('#modalProgramsMaster');
    const modalSpecilization = $('#modalProgramsSpecialization');
    modalMaster.removeClass('d-none');
    modalSpecilization.removeClass('d-none');

    const openModal = (name) => {
      if (name == 'master') {
        modalMaster.toggleClass('show');
      }
      if (name == 'specialization') {
        modalSpecilization.toggleClass('show');
      }
      $('html').toggleClass('overhidden');
    };

    const closeModal = (name) => {
      if (name == 'master') {
        modalMaster.removeClass('show');
      }
      if (name == 'specialization') {
        modalSpecilization.removeClass('show');
      }
      $('html').removeClass('overhidden');
    };

    $('.pages-internal.pages2 .box-buttons .btn').on('click', function (e) {
      e.preventDefault();
      let namePrograms = $(this).attr('data-target');

      if (userLogged) {
        if (namePrograms == 'maestrias') {
          openModal("master");
        }

        if (namePrograms == 'especializacion') {
          openModal("specialization");
        }
        return true;
      } else {
        $('#popregistro').toggleClass('show');
        $('html').toggleClass('overhidden');
      }

    });

    $('#modalProgramsMaster .close').on('click', function () {
      closeModal("master");
    });

    $('#modalProgramsSpecialization .close').on('click', function () {
      closeModal("specialization");
    });
  }
}

// Show modal postgraduate
function showModalPostgraduate() {
  if ($('#modalPostgraduate').length) {
    const modalPostgraduate = $('#modalPostgraduate');
    modalPostgraduate.removeClass('d-none');

    const openModal = () => {
      modalPostgraduate.toggleClass('show');
      $('html').toggleClass('overhidden');
    };

    const closeModal = () => {
      modalPostgraduate.removeClass('show');
      $('html').removeClass('overhidden');
    };

    $('.postgraduate .postgraduate__data__body .btn').on('click', function (e) {
      e.preventDefault();
      openModal();
    });

    $('#modalPostgraduate .close').on('click', function () {
      closeModal();
    });
  }
}

function showReferredModal() {
  if ($('#referred-form').length) {
    const referredformContainer =$('.referred-landing .right');
    const referredform = $('#referred-form');
    const mainModalLauncher = $('.sticky-mobile-btn');
    
    const openModal = () => {
      referredform.toggleClass('show');
      referredformContainer.toggleClass('show');
      mainModalLauncher.css('display', 'none');
      $('html').toggleClass('overhidden');
    };

    const closeModal = () => {
      referredform.removeClass('show');
      referredformContainer.toggleClass('show');
      mainModalLauncher.css('display', 'grid');
      $('html').removeClass('overhidden');
    };

    $('.sticky-mobile-btn, .mini-form-btn').on('click', function (e) {
      e.preventDefault();
      openModal();
    });

    $('.referred-form-btn-close').on('click', function () {
      closeModal();
    });
  }
}

function backToTopOfReferredPage() {
  if ($('.referred-landing').length) {
    const toTopButton = $('picture.sticky-footer-uparrow');
    toTopButton.on('click', function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, 100);
      return false;
    })
  }
}

function setIframeUrl() {
  if ($('.referred-landing').length) {
    
    const baseIframeSourceUrl = 'landing/landing-';
    const currentUrl = $(location).attr('href');
    const currentUrlEnd = currentUrl.slice(currentUrl.length - 18);
    const currentUrlStart = currentUrl.slice(0, -18);
    const devIframeSrc = 'https://dev-uniminuto-sombrillas.ariadna.co/';
    const qaIframeSrc = 'https://admisionesquas.uniminuto.edu/';
    const prodIframeSrc = 'https://admisiones.uniminuto.edu/';
    let iframeSrc = '' + baseIframeSourceUrl + currentUrlEnd;
    
    if (currentUrlStart == 'http://127.0.0.1:8000/'){
      iframeSrc = devIframeSrc + iframeSrc;
    }
    if (currentUrlStart == 'https://dev-uniminuto-sepro.ariadna.co/'){
      iframeSrc = devIframeSrc + iframeSrc;
    }
    // if (currentUrlStart == 'http://127.0.0.1:8000/'){
    //   iframeSrc = qaIframeSrc + iframeSrc;
    // }
    if (currentUrlStart == 'https://conveniosenauniminuto.inscripcionesuniminuto.info/'){
      iframeSrc = '' + prodIframeSrc + iframeSrc;
    }
    $('#referred-form').attr('src', iframeSrc);
  }
}

function sliderConferences() {
  if ($('.conferences').length) {

    $('.slider').owlCarousel({
      loop: false,
      margin: 10,
      nav: true,
      items: 1,
      dots: false,
    })
  }
}

// Custom form recovery password
function customFormRecoveryPassword() {
  if ($('#recoveryPassword').length) {
    const form = $('#recoveryPassword');
    form.find('#id_email')
      .attr('placeholder', 'Correo electrónico')
      .addClass('input input-text')
      .css('margin-bottom', '1.5rem');
  }
}

// Validate form register
function validateFormRegister() {
  if ($('.box-form-register').length) {

    $.validator.addMethod("lettersOnly", function (value, element) {

      return this.optional(element) || /^[a-zA-Z]+$/.test(value);
    }, "Solo se permiten letras");

    let validateFormRegister = $('.box-form #register').validate({
      rules: {
        campus: {
          required: true,
        },
        program: {
          required: true,
        },
        names: {
          required: true,
          lettersonlys: true,
          minlength: 3,
          maxlength: 30,
        },
        last_names: {
          required: true,
          lettersonlys: true,
          minlength: 3,
          maxlength: 50,
        },
        email: {
          required: true,
          emailValidate: true,
          email: true,
        },
        phone_number: {
          required: true,
          numericonly: true,
          minlength: 7,
          maxlength: 10,
        },
        document_type: {
          required: true,
        },
        document: {
          required: true,
          alphaNum: true,
          minlength: 6,
        },
        financing: {
          required: true,
        },
        tyc: {
          required: true,
        },
        regionals: {
          required: true,
        },
        financial: {
          required: true
        }
      },
      messages: {
        names: {
          required: "Por favor ingrese su nombre completo.",
          lettersonlys: "Por favor ingrese sólo letras."
        },
        last_names: {
          required: "Por favor ingrese su nombre completo.",
          lettersonlys: "Por favor ingrese sólo letras."
        },
        email: {
          required: "Por favor ingrese su correo electrónico.",
          emailValidate: "Por favor ingrese una dirección de correo electrónico válida.",
          email: "Por favor ingrese una dirección de correo electrónico válida.",
        },
        phone_number: {
          required: "Por favor ingrese su número de télefono",
          numericonly: "Por favor ingrese sólo números.",
          minlength: "Este campo debe tener mínimo 7 carácteres.",
          maxlength: "Este campo acepta un máximo de 10 cáracteres.",
        },
        document_type: {
          required: "Este campo es requerido",
        },
        document: {
          required: "Este campo es requerido.",
          alphaNum: "Ingrese un documento válido.",
          minlength: "Este campo debe tener mínimo 6 carácteres.",
        },
        tyc: {
          required: "Este campo es requerido.",
        },
        campus: {
          required: "Este campo es requerido.",
        },
        program: {
          required: "Este campo es requerido.",
        },

        regionals: {
          required: "Este campo es requerido.",
        },
        financial: {
          required: "Este campo es requerido.",
        }
      },
      submitHandler: function (form) {
        localStorage.setItem('sended', '1')
        form.submit();
      }
    });

    // $('#register #send').on('click', function (e) {
    //   e.preventDefault();
    //   let elementError = $('#errorFormSend');

    //   if (!validateFormRegister.form()) {
    //     // elementError.html('Por favor, complete los campos requeridos.');
    //     elementError.slideDown();
    //   }else {
    //     elementError.slideUp();
    //     localStorage.setItem('sended', '1')
    //     $('#register').submit();
    //   }
    // });

  }
}

// Validate form login
function validateFormLogin() {
  if ($('.box-form-login').length) {
    let validateFormLogin = $('.box-form #login').validate({
      rules: {
        email: {
          required: true,
          emailValidate: true,
        },
        password: {
          required: true,
        },
      },
      messages: {
        email: {
          required: "Por favor ingrese su correo electrónico.",
          emailValidate: "Por favor ingrese una dirección de correo electrónico válida.",
          email: "Por favor ingrese una dirección de correo electrónico válida.",
        },
        password: {
          required: "Este campo es requerido.",
        }
      },
      submitHandler: function (form) {
        form.submit();
      }
    });

    // $('#login #send').on('click', function (e) {
    //   e.preventDefault();
    //   let elementError = $('#errorFormSend');

    //   if (!validateFormLogin.form()) {
    //     elementError.html('Por favor, complete los campos requeridos.');
    //     elementError.slideDown();
    //   }else {
    //     elementError.slideUp();
    //     $('#login').submit();
    //   }
    // });
  }
}

// Validate form recovery password
function validateFormRecovery() {
  if ($('.box-form-recovery').length) {
    let validateFormRecovery = $('.box-form #recoveryPassword').validate({
      rules: {
        email: {
          required: true,
          emailValidate: true,
        },
      },
      submitHandler: function (form) {
        form.submit();
      }
    });

    $('#recoveryPassword #send').on('click', function (e) {
      e.preventDefault();
      let elementError = $('#errorFormSend');

      if (!validateFormRecovery.form()) {
        elementError.html('Por favor, complete los campos requeridos.');
        elementError.slideDown();
      } else {
        elementError.slideUp();
        $('#recoveryPassword').submit();
      }
    });
  }
}

// Event onchage select campus to program
function eventOnChangeSelectCampus() {
  if ($('#register').length) {
    $('#register #campusProgram').on('change', function () {
      let valueId = $(this).val();
      let selectProgram = $('#register #program');

      const resetSelect = () => {
        selectProgram.empty();
        selectProgram.append(`<option value="" selected="">Programa de interés</option>`);
      }

      // show data endpoint
      $.ajax({
        url: `/programa/program/${valueId}/`,
        type: 'GET',
        success: function (data) {
          resetSelect();
          let arrayList = Object.entries(data);
          if (arrayList[0][0] == "error") {
            resetSelect();
          } else {
            for (let i = 0; i < arrayList.length; i++) {
              selectProgram.append(`<option value="${arrayList[i][0]}">${arrayList[i][1]}</option>`);
            }
          }

        },
        error: function (error) {
          resetSelect();
        }
      });


    });
  }
}

// Add class and placeholder for input recovery password
function addClassForInputRecovery() {
  if ($('#restorePassword').length) {
    $('#restorePassword [name="new_password1"]')
      .addClass('input input-text')
      .attr('placeholder', 'Nueva contraseña');
    $('#restorePassword [name="new_password2"]')
      .addClass('input input-text')
      .attr('placeholder', 'Confirmar contraseña');
  }
}

function buildBenefitsSlider() {
  if ($('.benefits-section').length) {
    $('.benefits-section .benefits').addClass('owl-carosuel')
    $('.benefits-section .owl-carosuel').owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      dots: true,
      items: 2,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1,
        },
        1024: {
          items: 2,
        }
      }
    })
  }

  if ($('.benefits-landing-section').length) {
    $('.benefits-landing-section .benefits').addClass('owl-carosuel')
    $('.benefits-landing-section .owl-carosuel').owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      dots: true,
      items: 4,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2,
        },
        1024: {
          items: 3,
        },
        1200: {
          items: 4,
          nav: false,
          dots: false,
        }
      }
    })
  }
}


function scrollByTab(selector) {
  jQuery(selector).on('click', function (event) {

    event.preventDefault(); // evita el comportamiento predeterminado del enlace

    let section = jQuery('.row .information')
    const sectionPosition = section.offset().top;
    const scrollOffset = sectionPosition + (-80); // Calcula el desplazamiento de la página

    window.scrollTo({
      top: scrollOffset,
      behavior: 'smooth' // opcional, para animar el desplazamiento
    });
  });
}

function triggerSuccessAlert() {
  let validateForm = localStorage.getItem('sended')

  if (validateForm === "1") {

    $('#popregistro').removeClass('show');
    $('html').removeClass('overhidden');

    Swal.fire({
      imageUrl: 'static/uniminuto/dist/assets/images/icon-check-register-complete.png',
      imageAlt: 'A tall image',
      title: 'Buen trabajo. Registro exitoso!',
      // text: 'Tus datos fueron enviados exitosamente!',
      heightAuto: false,
      confirmButtonColor: '#39A900',
      iconColor: '#39A900',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/iniciar-sesion'
      }
    })

    localStorage.setItem('sended', '0')
  }
}

// Event onchage select campus to program
function eventOnChangeSelectCity() {
  if ($('#register').length) {
    $('#register #program').on('change', function () {
      let valueId = $(this).val();
      let selectProgram = $('#register #cityProgram');

      const resetSelect = () => {
        selectProgram.empty();
        selectProgram.append(`<option value="" selected="">Ciudad de interés</option>`);
      }

      // show data endpoint
      $.ajax({
        url: `/programa/city/${valueId}/`,
        type: 'GET',
        success: function (data) {
          resetSelect();
          let arrayList = Object.entries(data);
          if (arrayList[0][0] == "error") {
            resetSelect();
          } else {
            for (let i = 0; i < arrayList.length; i++) {
              selectProgram.append(`<option value="${arrayList[i][0]}">${arrayList[i][1]}</option>`);
            }
          }

        },
        error: function (error) {
          resetSelect();
        }
      });


    });
  }
}


function buildMainStepsSlider() {
  if ($('.main-steps').length) {

    $('.register-steps .main-steps').addClass('owl-carosuel')
    $('.register-steps .owl-carosuel').owlCarousel({
      loop: true,
      margin: 0,
      nav: false,
      dots: true,
      items: 1,
      onInitialized: callCloneDots,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1,
        },
        1024: {
          items: 1,
        }
      },
    })

    function callCloneDots(event) {
      // Clonar los dots
      let dots = $('.main-steps .owl-dots');
      let dotsCloned = dots.find('.owl-dot').clone()
      $('.register-steps').prepend('<div class="owl-indicators"></div>')
      $('.register-steps .owl-indicators').append(dotsCloned)
      // Personalizar la estructura HTML de los dots clonados
      dotsCloned.each(function (index) {
        $(this).addClass('custom-dot');
        $(this).attr('data-step', index)
        $(this).html(`<span role="button" class="step-indicator">${index + 1}</span>`);
        $(this).on('click', function (e) {
          $('.owl-indicators .custom-dot').removeClass('active')
          $(this).addClass('active')
          let dotIndex = $(this).index();
          $('.register-steps .owl-carosuel').trigger('to.owl.carousel', [dotIndex, 300]);
        });
      });

      dots.find('.owl-dot').each(function (i) {
        $(this).on('click', function (e) {

          let dotIndex = $(this).index();
          let dotsCloned = $('.owl-indicators .custom-dot')

          dotsCloned.each(function (i, ele) {
            let dataStep = jQuery(ele).attr('data-step')
            if (Number(dataStep) === dotIndex) {
              dotsCloned.removeClass('active')
              jQuery(this).addClass('active')
            }
          })
          $('.register-steps .owl-carosuel').trigger('to.owl.carousel', [dotIndex, 300]);
        });
      })
    };


  }
}


function buildSiteCarousel() {
  if ($('.geolocation-section .sites-info').length) {
    let owl = $('.geolocation-section .sites-info-mobile').addClass('owl-carosuel')
    owl.owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      dots: true,
      items: 1,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1,
        },
        1024: {
          items: 1,
        }
      },
    })
  }
}


function buildServicesCarousel() {
  if ($('.services-section .services').length) {

    let owl = $('.services-section .services').addClass('owl-carosuel')
    owl.owlCarousel({
      loop: false,
      margin: 10,
      nav: false,
      dots: true,
      items: 1,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1,
        },
        1024: {
          items: 2,
          nav: false,
          dots: false,
        }
      },
    })
  }
}

function buildEventsCarousel() {
  if ($('.events .events-list').length) {

    let owl = $('.events .events-list').addClass('owl-carosuel')
    owl.owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      dots: true,
      items: 1,
      // autoWidth: true,
      stagePadding: 50,
      responsive: {
        0: {
          items: 1,

        },
        600: {
          items: 2,
        },
        1024: {
          items: 2,
          nav: false,
        },
        1200: {
          items: 2,
          nav: false,
          stagePadding:0,
        }
      },
    })
  }
}



function mapDateEvents() {
  if ($('.events .events-list').length > 1) {
    let events = $('.events .events-list .events-card')
    events.each(function (i, elem) {
      let eventDate = $(elem).attr('data-date')
      let eventHour = $(elem).attr('data-time')

      let date = new Date;
      let formattedDate = date.toLocaleDateString('es-ES')
      let formattedHour = date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
      let addHours = new Date(date.setHours(date.getHours() + 2));

      let previousHour = addHours.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });





      if (formattedDate === eventDate) {
        let eventBtn = $(elem).find('button.btn-event')

        if (eventHour < previousHour) {
          eventBtn.addClass('soon')
          eventBtn.text('Ver vídeo')
        } else {
          eventBtn.removeClass('soon')
          eventBtn.text('Próximamente')
        }

        if (eventHour === formattedHour) {
          eventBtn.removeClass('soon').addClass('live')
          eventBtn.text('En vivo')
        } else if (formattedHour > eventHour) {
          eventBtn.addClass('soon')
          eventBtn.text('Ver vídeo')
        }




      }
    })
  }
}

function hiddenProgramSection() {
  if ($('.find-program .find-program-info .program-card-list .program-card').length >= 1) {
    let additionInfo = $('.find-program .additional-program-info')
    $('.find-program .questions').fadeOut(300).addClass('d-none')
    additionInfo.fadeOut(300).addClass('d-none')

    let programModeActions = $(".find-program .program-mode")
    programModeActions.removeClass('d-none')

    let varButtonActive = localStorage.getItem('prog-mod')
    $('.find-program .program-mode .program-mode-actions button').removeClass('active')
    let selectorBtn = `.find-program .program-mode .program-mode-actions button.${varButtonActive}`
    $(selectorBtn).addClass('active')

    $(".find-program .program-mode .program-mode-actions button").on('click', function (e) {
      e.preventDefault()
      // $('.find-program .program-mode .program-mode-actions button').removeClass('active')

      let dataValue = $(e.target).attr('data-value')
      $('.find-program .find-program-form .input-program-mode input').each(function (i, elem) {
        $(elem).removeAttr('checked')
        let value = $(elem).attr('value')
        if (value === dataValue) {
          $(elem).attr('checked', true)
          localStorage.setItem('prog-mod', dataValue)
          $('.find-program .find-program-form input[type="submit"]').click()
        } else {
        }
      })
    })

  } else {
    $('.find-program .find-program-info .program-card-list').fadeOut(300)
    localStorage.removeItem('prog-mod')
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search)


    if (searchParams.has('title') || searchParams.has('type')) {
      // Eliminar los parámetros de búsqueda de la URL
      let cleanUrl = window.location.href.split('?')[0];
      // Redirigir a la URL limpia
      window.location.href = cleanUrl;
    }

  }
}


function openModalForRegister() {
  let menuLinks = $('#sepro-menu .navbar-nav .nav-item .nav-link, #menu .navbar-nav .nav-link')
  let alertRegister = $('#modalAlertRegister')

  const openModal = () => {
    alertRegister.toggleClass('show');
    $('html').toggleClass('overhidden');
  };

  const closeModal = () => {
    alertRegister.removeClass('show');
    $('html').removeClass('overhidden');
  };

  $('#modalAlertRegister .close').on('click', function () {
    closeModal();
  });

  if (userLogged === 'False') {
    $(".navbar-nav .nav-item a").not("[href='/']").click(function (e) {
      e.preventDefault()
      openModal();

    })
  } else {
    return false;
  }


}


function buildFindProgramStepsCarousel() {
  if ($('.find-program .additional-program-info .steps-list').length) {
    if ($(window).width() <= 998) {
      let owl = $('.find-program .additional-program-info .steps-list').addClass('owl-carosuel')
      owl.owlCarousel({
        loop: false,
        margin: 10,
        nav: false,
        dots: true,
        items: 1,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1024: {
            items: 4,
            nav: false,
            dots: false,
          },
          1200: {
            items: 4,
            nav: false,
            dots: false,
          }
        },
      })
    }

  }
}


function buildQuestionsCarousel() {
  if ($('.find-program .find-program-info .questions').length) {
    if ($(window).width() <= 998) {

      let owl = $('.find-program .find-program-info .questions-block-list').addClass('owl-carosuel')
      owl.owlCarousel({
        loop: false,
        margin: 10,
        nav: false,
        dots: true,
        items: 1,
        responsive: {
          0: {
            items: 1,

          },
          600: {
            items: 1,
          },
          1024: {
            items: 1,
            nav: false,
          }
        },
      })
    }
  }

}

function validateFindPogramForm() {
  if ($('.find-program-form').length) {
    $('.find-program-form form').validate({
      rules: {
        type: {
          required: true,
        },
        name: {
          required: true,
        },
      },
      messages: {
        name: {
          required: "Este campo es requerido.",
        },
        type: {
          required: "Este campo es requerido.",
        },

      },
      submitHandler: function (form) {
        form.submit();
      }
    });
  }
}



function OnChangeSelectTypeProgram() {
  if ($('.find-program-form form').length) {

    let selectProgram = $('.find-program-form form .select-program select');

    const resetSelect = () => {
      selectProgram.empty();
      selectProgram.append(`<option value="" selected="">Selecciona tu titulo</option>`);
    }
    // show data endpoint
    function getProgramTypes(valueId) {
      $.ajax({
        url: `/programa/tipo-programa/${valueId}/`,
        type: 'GET',
        success: function (data) {
          resetSelect();
          let arrayList = Object.entries(data);
          if (arrayList[0][0] == "error") {
            resetSelect();
          } else {
            for (let i = 0; i < arrayList.length; i++) {
              selectProgram.append(`<option value="${arrayList[i][0]}">${arrayList[i][1]}</option>`);
            }
          }

        },
        error: function (error) {
          resetSelect();
        }
      });
    }


    const params = new URL(location.href).searchParams;
    const type = params.get('type');

    if (type) {
      getProgramTypes(type)
    }

    $('input[name="type"]').on('change', function () {

      let valueId = $("input[name='type']:checked").val();

      getProgramTypes(valueId)

    });



  }
}

function buildReferredSlider() {
  if ($('.referred-landing').length) {
    if ($(window).width() <= 768) {
      $('.referred-landing .article-grid').addClass('owl-carosuel')
      $('.referred-landing .owl-carosuel').owlCarousel({
        loop: false,
        margin: 20,
        nav: false,
        dots: true,
        items: 2,
        responsive: {
          0: {
            items: 1
          },
          320: {
            items: 1,
          },
          768: {
            items: 2,
          }
        }
      })
      if($('.referred-landing').hasClass('pregrado')) {
        $('.referred-landing .owl-carosuel .owl-dots .owl-dot').addClass('pregrado')
      }
      if($('.referred-landing').hasClass('posgrado')) {
        $('.referred-landing .owl-carosuel .owl-dots .owl-dot').addClass('posgrado')
      }
    }
  }
}


document.addEventListener('DOMContentLoaded', function () {
  validatePathURL();
  AOS.init();
  verifyUserLogged();
  addMethodValidate();
  listeningScroll();
  // listenClickMenu();
  showMenuNavigation();
  showModalLogin();
  loadDataPostgraduateModal();
  showModalPrograms();
  showModalPostgraduate();
  showReferredModal();
  backToTopOfReferredPage();
  customFormRecoveryPassword()
  sliderConferences();
  eventOnChangeSelectCampus();
  validateFormRegister();
  validateFormLogin();
  validateFormRecovery();
  addClassForInputRecovery();
  buildBenefitsSlider();
  triggerSuccessAlert();
  eventOnChangeSelectCity();
  buildMainStepsSlider();
  buildSiteCarousel();
  buildServicesCarousel();
  // buildEventsCarousel();
  mapDateEvents();
  hiddenProgramSection();
  // openModalForRegister();
  buildFindProgramStepsCarousel();
  buildQuestionsCarousel();
  validateFindPogramForm();
  OnChangeSelectTypeProgram();
  buildReferredSlider();
  setIframeUrl();
});